/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { isBrowser } from "react-device-detect"

const Container = ({ isFluid, ...props }) => (
  <div
    {...props}
    sx={{
      minWidth: isFluid ? "none" : ["140px", "140px", "170px"],
      width: isFluid ? "none" : "170px",
      transition: "opacity 0.1s ease-in-out",
      ":hover": {
        opacity: isBrowser ? 0.7 : "inherit",
      },
    }}
  />
)

const Title = props => (
  <div
    {...props}
    sx={{
      color: "legend",
      fontSize: "15px",
      fontWeight: 300,
      textAlign: "center",
      marginTop: "7px",
      minHeight: "3em",
    }}
  />
)

const PersonneItem = ({ personne, isFluid }) => {
  return (
    <Container isFluid={isFluid}>
      <Link to={`/personne/${personne.slug}`}>
        <Img
          fluid={personne.Image_principale_optimized.childImageSharp.fluid}
          alt={personne.CACHE_Nom_complet}
          placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
        />
      </Link>
      <Title>{personne.CACHE_Nom_complet}</Title>
    </Container>
  )
}

export default PersonneItem
