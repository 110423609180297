/** @jsx jsx */
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import { isBrowser } from "react-device-detect"

const Container = ({ isFluid, other, ...props }) => (
  <div
    {...props}
    sx={{
      minWidth: isFluid ? "none" : ["180px", "180px", "210px"],
      width: isFluid ? "none" : "210px",
      transition: "opacity 0.1s ease-in-out",
      opacity: other ? 0.5 : 1,
      // transform: other ? "scale(0.9)" : "scale(1)",
      ":hover": {
        opacity: isBrowser ? (other ? 0.5 : 0.7) : "inherit",
      },
    }}
  />
)

const FilmItem = ({ film, isFluid, year }) => {
  return (
    film &&
    film.Affiche_optimized &&
    film.Affiche_optimized.childImageSharp &&
    film.Affiche_optimized.childImageSharp.fluid && (
      <Container isFluid={isFluid} other={!film.slug}>
        {year && (
          <div sx={{ textAlign: "center", mb: "0.1rem", color: "#999" }}>
            {new Date(film.Sortie).getFullYear()}
          </div>
        )}
        {film.slug ? (
          <Link to={`/film/${film.slug}`}>
            <Img
              fluid={film.Affiche_optimized.childImageSharp.fluid}
              alt={film.Titre_FR}
              placeholderStyle={{ opacity: 1, filter: "blur(10px)" }}
            />
          </Link>
        ) : (
          <Img
            fluid={film.Affiche_optimized.childImageSharp.fluid}
            alt={film.Titre_FR}
            placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
          />
        )}
      </Container>
    )
  )
}

export default FilmItem
