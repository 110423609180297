import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export function JsonLd({ children }) {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(children)}</script>
    </Helmet>
  )
}

// for open graph (og)
// const actors = []
// const directors = []
// const images = []

const pelleasOragnizationJsonld = {
  "@type": "Organization",
  name: "Les Films Pelléas",
  url: "https://lesfilmspelleas.com/",
  address: {
    "@type": "PostalAddress",
    streetAddress: "25 rue Michel Le Comte",
    postalCode: "75003",
    addressLocality: "Paris",
    addressCountry: "FR",
    telephone: "+33 1 42 74 31 00",
  },
  sameAs: [
    "https://www.imdb.com/company/co0346444/",
    "https://fr.wikipedia.org/wiki/Les_Films_Pell%C3%A9as",
    "http://www.allocine.fr/societe/fichesociete-1283/activite-15003/",
    "https://cineuropa.org/fr/prodcompany/8228/",
    "https://en.unifrance.org/directories/company/55464/les-films-pelleas",
    "https://twitter.com/lesfilmspelleas",
    "https://www.facebook.com/filmspelleas/",
    "https://www.youtube.com/channel/UCZJ864x7XoRWO0zD7rSOU7Q",
    "https://www.instagram.com/lesfilmspelleas/",
  ],
}

const CreationSEO = ({ creation, siteUrl }) => {
  const realList =
    creation.Realisateurs &&
    creation.Realisateurs.map((realisateur, i) =>
      i > 0
        ? ` ${realisateur.CACHE_Nom_complet}`
        : realisateur.CACHE_Nom_complet
    ) + ". "
  const realString = `De ${realList}`
  const creationUrl = creation.isArtNum
    ? `${siteUrl}/artnum/${creation.slug}`
    : `${siteUrl}/film/${creation.slug}`

  return (
    <React.Fragment>
      <Helmet
        meta={[
          {
            name: "description",
            content: `${realString} ${creation.Synopsis_FR}`,
          },
          {
            property: "og:type",
            content: "video.movie",
          },
          {
            property: "og:title",
            content: creation.Titre_FR,
          },
          {
            property: "og:description",
            content: `${realString} ${creation.Synopsis_FR}`,
          },
          // multiple images to add
          {
            property: "og:image",
            content: creation.Image_principale,
          },
          {
            property: "og:video:release_date",
            content: creation.Sortie,
          },
          {
            property: "og:url",
            content: creationUrl,
          },
          // video:actor
          // video:director
          // video:duration
          {
            name: "twitter:card",
            content: "photo",
          },
          // *** Twitter will use the OpenGraph metadata ***
          // {
          //   name: "twitter:title",
          //   content: creation.Titre_FR,
          // },
          // {
          //   name: "twitter:description",
          //   content: creation.Synopsis_FR,
          // },
          // {
          //   name: "twitter:image",
          //   content: creation.Affiche,
          // },
        ]}
      />
      <JsonLd>
        {{
          "@context": "https://schema.org",
          "@type": "Movie",
          url: creationUrl,
          name: creation.Titre_FR,
          image: creation.Affiche,
          dateCreated: creation.Sortie,
          countryOfOrigin: "FR",
          director: creation.Realisateurs.map(real => ({
            "@type": "Person",
            name: real.CACHE_Nom_complet,
            sameAs: [real.IMDB_URL, real.Wikipedia_URL],
            url: `${siteUrl}/personne/${real.slug}`,
          })),
          description: `${realString} ${creation.Synopsis_FR}`,
          // abstract: creation.Synopsis_FR,
          actor: [creation.Cast && creation.Cast.split(", ").map(item => item)],
          // "duration": film.Duree ? parseDuration(film.Duree + " mins")*1000 : null,
          productionCompany: pelleasOragnizationJsonld,
          // "producer": [],
          sameAs: [
            creation.IMDB_URL,
            creation.Wikipedia_URL,
            creation.Allocine_URL,
          ],
          trailer: {
            "@type": "VideoObject",
            embedUrl: creation.Video_principale_URL,
            description: creation.Synopsis_FR,
            name: creation.Titre_FR,
            thumbnailUrl: creation.Image_principale,
            uploadDate: creation.Sortie,
          },
          isFamilyFriendly: true,
          award:
            creation.Nominations_prix &&
            creation.Nominations_prix.split("\n").map(item => item),
        }}
      </JsonLd>
      <Helmet>
        <link rel="canonical" href={creationUrl} />
      </Helmet>
    </React.Fragment>
  )
}

const PersonneSEO = ({ personne, siteUrl }) => {
  const personneUrl = `${siteUrl}/personne/${personne.slug}`
  return (
    <React.Fragment>
      <Helmet
        meta={[
          {
            name: "description",
            content: personne.Bio_FR,
          },
          {
            property: "og:type",
            content: "profile",
          },
          {
            property: "og:title",
            content: personne.CACHE_Nom_complet,
          },
          {
            property: "og:description",
            content: personne.Bio_FR,
          },
          // multiple images to add
          {
            property: "og:image",
            content: personne.Image_principale,
          },
          {
            property: "og:url",
            content: personneUrl,
          },
        ]}
      />
      <JsonLd>
        {{
          "@context": "http://schema.org",
          "@type": "Person",
          name: personne.CACHE_Nom_complet,
          image: personne.Image_principale,
          description: personne.Bio_FR,
          jobTitle: "Director",
          sameAs: [personne.IMDB_URL, personne.Wikipedia_URL],
          url: personneUrl,
        }}
      </JsonLd>
      <Helmet>
        <link rel="canonical" href={personneUrl} />
      </Helmet>
    </React.Fragment>
  )
}

const ContactSEO = () => (
  <React.Fragment>
    <JsonLd>{pelleasOragnizationJsonld}</JsonLd>
    <Helmet>
      <link rel="canonical" href={`https://lesfilmspelleas.com/contact/`} />
    </Helmet>
  </React.Fragment>
)

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  location,
  creation,
  personne,
  contact,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  // const url = `${site.siteMetadata.siteUrl}${location ? location.pathname : ""}`

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: "description",
            content: site.siteMetadata.description,
          },
          {
            property: "og:site_name",
            content: site.siteMetadata.title,
          },
          {
            name: "twitter:site",
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
        ].concat(meta)}
      />
      {creation && (
        <CreationSEO creation={creation} siteUrl={site.siteMetadata.siteUrl} />
      )}
      {personne && (
        <PersonneSEO personne={personne} siteUrl={site.siteMetadata.siteUrl} />
      )}
      {contact && (
        <ContactSEO contact={contact} siteUrl={site.siteMetadata.siteUrl} />
      )}
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
