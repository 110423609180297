/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import {
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaVimeoSquare,
  FaAlignLeft,
} from "react-icons/fa"
import TextTruncate from "react-text-truncate"
import { isBrowser } from "react-device-detect"

const Container = ({ isFluid, ...props }) => (
  <div
    {...props}
    sx={{
      minWidth: isFluid ? "none" : ["220px", "220px", "260px"],
      width: isFluid ? "none" : ["220px", "220px", "260px"],
      transition: "opacity 0.1s ease-in-out",
      ":hover": {
        opacity: isBrowser ? 0.7 : "inherit",
      },
    }}
  />
)

const Thumbnail = props => (
  <div
    {...props}
    sx={{
      position: "relative",
    }}
  />
)

const Logo = props => (
  <div
    {...props}
    sx={{
      position: "absolute",
      top: "7px",
      left: "7px",
      opacity: 1,
    }}
  />
)

const Title = props => (
  <div
    {...props}
    sx={{
      color: "legend",
      fontSize: "1.05em",
      fontWeight: 600,
      textAlign: "left",
      marginTop: "7px",
    }}
  />
)

const ActualiteItem = ({ actualite, isFluid }) => {
  return (
    actualite &&
    actualite.Image_principale_optimized &&
    actualite.Image_principale_optimized.childImageSharp &&
    actualite.Image_principale_optimized.childImageSharp.fluid && (
      <Container isFluid={isFluid}>
        <Thumbnail>
          <a
            href={actualite.Lien_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              fluid={actualite.Image_principale_optimized.childImageSharp.fluid}
              alt={actualite.Titre_FR}
              placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
            />
            <Logo>
              {actualite.Source === "Facebook" && (
                <FaFacebookSquare color={"#FFF"} size={"25px"} />
              )}
              {actualite.Source === "Instagram" && (
                <FaInstagram color={"#FFF"} size={"25px"} />
              )}
              {actualite.Source === "Twitter" && (
                <FaTwitter color={"#FFF"} size={"25px"} />
              )}
              {actualite.Source === "YouTube" && (
                <FaYoutube color={"#FFF"} size={"25px"} />
              )}
              {actualite.Source === "Vimeo" && (
                <FaVimeoSquare color={"#FFF"} size={"25px"} />
              )}
              {actualite.Source === "Article" && (
                <FaAlignLeft color={"#FFF"} size={"25px"} />
              )}
            </Logo>
          </a>
        </Thumbnail>
        <div sx={{ mx: [3, 0], fontSize: "0.9em" }}>
          <Title>{actualite.Titre_FR}</Title>
          <TextTruncate
            line={3}
            element="span"
            truncateText=" ..."
            text={actualite.Description_FR}
            sx={{
              color: "gray",
            }}
          />
        </div>
      </Container>
    )
  )
}

export default ActualiteItem
