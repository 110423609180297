/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { isBrowser } from "react-device-detect"

import logo from "../images/3emeScene.svg"

const Container = ({ isFluid, ...props }) => (
  <div
    {...props}
    sx={{
      minWidth: isFluid ? "none" : ["340px", "340px", "400px"],
      width: isFluid ? "none" : "400px",
      transition: "opacity 0.1s ease-in-out",
      position: "relative",
      ":hover": {
        opacity: isBrowser ? 0.7 : "inherit",
      },
    }}
  />
)

const Logo = props => (
  <img
    {...props}
    alt={props.alt} // to prevent the warning 'img elements must have an alt prop...'
    sx={{
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "90px",
      margin: "10px",
      opacity: 0.8,
    }}
  />
)

const Overlay = props => (
  <div
    {...props}
    sx={{
      position: "absolute",
      backgroundColor: "#000",
      top: 0,
      left: 0,
      opacity: 0.05,
      height: "100%",
      width: "100%",
    }}
  />
)

const ActuText = props => (
  <div
    {...props}
    sx={{
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      color: "rgba(255, 255, 255, 0.8)",
      padding: "25px 25px 20px 25px",
      fontSize: 1,
      // height: "100px",
    }}
  />
)

const ArtNumItem = ({ artNum, isFluid }) => {
  return (
    <Container isFluid={isFluid}>
      <Link to={`/artnum/${artNum.slug}`}>
        <Img
          fluid={artNum.Affiche_optimized.childImageSharp.fluid}
          alt={artNum.Titre_FR}
          placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
        />
        <Overlay />
        <Logo src={logo} alt="Pelleas Logo" />
        <ActuText>
          <Styled.h1
            sx={{
              fontWeight: 300,
              margin: 0,
              padding: 0,
              lineHeight: "1em",
              color: "#FFF",
            }}
          >
            {artNum.Titre_FR}
          </Styled.h1>
          <Styled.p
            sx={{
              fontWeight: 300,
              marginTop: 1,
              padding: 0,
              color: "#FFF",
            }}
          >
            {artNum.CACHE_Realisateurs}
          </Styled.p>
        </ActuText>
      </Link>
    </Container>
  )
}

export default ArtNumItem

/*
TODO: Place a div in absolute position, make it  flex, add the element in it in vertical
*/
