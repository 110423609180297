/** @jsx jsx */
import { jsx } from "theme-ui"

const Box = props => (
  <div
    {...props}
    sx={{
      px: 3,
      textDecoration: "none",
      color: "#999",
    }}
  />
)

const Footer = () => {
  return (
    <div
      sx={{
        backgroundColor: "#222",
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        py: "10px",
      }}
    >
      <Box>LES FILMS PELLÉAS</Box>
      <Box>25 rue Michel Le Comte 75003 Paris</Box>
      <Box>+33 1 42 74 31 00</Box>
    </div>
  )
}

export default Footer
