/** @jsx jsx */
import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { jsx, useThemeUI } from "theme-ui"
import { MdMenu, MdClose } from "react-icons/md"
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa"

const Bar = props => (
  <div
    {...props}
    sx={{
      backgroundColor: "background",
      borderBottomColor: "rgb(231, 231, 231)",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",

      height: "61px",
      padding: "0px 10px",

      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      position: "sticky",
      top: "0px",
      zIndex: "1",
    }}
  />
)

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_pelleas.png" }) {
        childImageSharp {
          fluid(maxWidth: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Link
      to={"/"}
      sx={{
        paddingTop: "0px",
        paddingLeft: "10px",
        verticalAlign: "middle",
      }}
    >
      <Img
        fluid={data.logo.childImageSharp.fluid}
        style={{ width: `90px`, margin: `0px 0px` }}
        alt="Logo"
        placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
      />
    </Link>
  )
}

const SocialButtons = () => {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        a: {
          textDecoration: "none",
          color: "inherit",
          p: 0,
          mx: 1,
          lineHeight: 0,
        },
        svg: {
          color: "#DDD",
          size: "1.5em",
          ":hover": {
            color: "#777",
          },
        },
      }}
    >
      <a
        href={"https://www.facebook.com/filmspelleas/"}
        target="_blank"
        rel="noopener noreferrer"
        alt="Facebook"
      >
        <FaFacebookSquare />
      </a>
      <a
        href={"https://www.instagram.com/lesfilmspelleas/"}
        target="_blank"
        rel="noopener noreferrer"
        alt="Instagram"
      >
        <FaInstagram />
      </a>
      <a
        href={"https://www.youtube.com/channel/UCZJ864x7XoRWO0zD7rSOU7Q"}
        target="_blank"
        rel="noopener noreferrer"
        alt="YouTube"
      >
        <FaYoutube />
      </a>
      <a
        href={"https://twitter.com/lesfilmspelleas"}
        target="_blank"
        rel="noopener noreferrer"
        alt="Twitter"
      >
        <FaTwitter />
      </a>
    </div>
  )
}

const MenuBar = props => (
  <nav
    {...props}
    sx={{
      display: ["none", "none", "inherit"],
    }}
  />
)

const MenuItem = ({ isDrawer = false, ...props }) => {
  const context = useThemeUI()
  const { theme } = context
  return (
    <Link
      {...props}
      sx={{
        // padding: "0px 12px",
        color: "#707070", // Pelleas color: #6C7256
        fontSize: "16px",
        fontWeight: "400",
        textDecorationLine: "none",
        py: isDrawer ? 2 : 0,
        px: isDrawer ? 3 : "12px",
      }}
      // Theme-UI way doesn't work: https://theme-ui.com/recipes/gatsby-link
      activeStyle={{ color: theme.colors.primary }}
    />
  )
}

const MenuIcon = ({ setDrawer, ...props }) => (
  <div
    sx={{
      display: ["inherit", "inherit", "none"],
      my: 0,
      py: 0,
    }}
  >
    <MdMenu
      color={"#707070"}
      size={"2em"}
      sx={{ cursor: "pointer" }}
      onClick={() => setDrawer(true)}
    />
  </div>
)

const MenuDrawer = ({ setDrawer, isOpen, ...props }) => (
  <div
    sx={{
      width: "100%",
      height: "100%",
      background: "white",
      // boxShadow: isOpen ? "1px 0px 7px rgba(0, 0, 0, 0.5)" : "",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 20,
      transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
      transition: "transform 0.3s ease-out",
    }}
  >
    <div
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "61px",
      }}
    >
      <MdClose
        color={"#707070"}
        size={"1.8em"}
        onClick={() => setDrawer(false)}
        sx={{ cursor: "pointer", m: 2 }}
      />
    </div>
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MenuItem to="/" isDrawer={true}>
        HOME
      </MenuItem>
      <MenuItem to="/film/" partiallyActive={true} isDrawer={true}>
        FILMS
      </MenuItem>
      <MenuItem to="/realisateur/" isDrawer={true}>
        CINÉASTES
      </MenuItem>
      <MenuItem to="/artnum/" partiallyActive={true} isDrawer={true}>
        ART NUMÉRIQUE
      </MenuItem>
      {/* <MenuItem to="/artiste/">ARTISTES</MenuItem> */}
      <MenuItem to="/actualite/" isDrawer={true}>
        ACTUALITÉS
      </MenuItem>
      <MenuItem to="/contact/" isDrawer={true}>
        CONTACT
      </MenuItem>
    </div>
  </div>
)

const AppBar = () => {
  const [drawer, setDrawer] = useState(false)
  return (
    <React.Fragment>
      <MenuDrawer setDrawer={setDrawer} isOpen={drawer} />
      <Bar>
        <Logo />
        <SocialButtons />
        <MenuIcon setDrawer={value => setDrawer(value)} />
        <MenuBar>
          <MenuItem to="/">HOME</MenuItem>
          <MenuItem to="/film/" partiallyActive={true}>
            FILMS
          </MenuItem>
          <MenuItem to="/realisateur/">CINÉASTES</MenuItem>
          <MenuItem to="/artnum/" partiallyActive={true}>
            ART NUMÉRIQUE
          </MenuItem>
          {/* <MenuItem to="/artiste/">ARTISTES</MenuItem> */}
          <MenuItem to="/actualite/">ACTUALITÉS</MenuItem>
          <MenuItem to="/contact/">CONTACT</MenuItem>
        </MenuBar>
      </Bar>
    </React.Fragment>
  )
}

export default AppBar
