/** @jsx jsx */
import { jsx } from "theme-ui"

const Section = ({ isGrey, ...props }) => (
  <div
    {...props}
    sx={{
      backgroundColor: isGrey ? "lightgray" : "background",
      pt: 4,
      pb: 4,
    }}
  />
)

Section.Title1 = props => (
  <div
    {...props}
    sx={{
      textAlign: "center",
      variant: "text.sectionTitle1",
    }}
  />
)

Section.Title2 = props => (
  <div
    {...props}
    sx={{
      textAlign: "center",
      variant: "text.sectionTitle2",
    }}
  />
)

export default Section
