import React from "react"
import { Styled } from "theme-ui"
import "./layout.css"

import AppBar from "./app-bar"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <Styled.root>
      <AppBar />
      <main>{children}</main>
      <Footer />
    </Styled.root>
  )
}

export default Layout
