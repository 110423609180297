/** @jsx jsx */
import { jsx } from "theme-ui"

const Container = ({ isFull, isText, ...props }) => (
  <div
    sx={{
      maxWidth: isFull ? "none" : "1400px",

      margin: "0 auto",
      padding: [isText ? "0 20px" : "0 4px", "0 20px", "0 20px"],
    }}
    {...props}
  />
)

export default Container
